import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
// import { CSVLink } from "react-csv";


export default function AllResults() {
  const [Projects1, setProjects1] = React.useState([])
  const [Projects2, setProjects2] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [StudentsList, setStudentsList] = React.useState([])
  const [StudentsList1, setStudentsList1] = React.useState([])
  const [ExamType, setExamType] = React.useState([])
  const [ExamList, setExamList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [AllTotal, setAllTotal] = React.useState(0)
  const [serachStudent, setSearchStudent] = React.useState("")
  const [serachExam, setSearchExam] = React.useState("")
  const [serachExamID, setSearchExamID] = React.useState("")
  const [SubscriberTotal, setSubscriberTotal] = React.useState(0)
  const [StudentTotal, setStudentTotal] = React.useState(0)
  const [AdminTotal, setAdminTotal] = React.useState(0)
  const [ExamTotal, setExamTotal] = React.useState(0)
  const [choose, setChoose] = React.useState(0)
  const [batchList, setBatchList] = React.useState([])
  const [bulkDelete, setBulkDelete] = React.useState(false)
  const date = new Date();
  const [CustomersDownload, setCustomersDownload] = React.useState([])


  const headers = [
    { label: "Nama Ujian", key: "name" },
    { label: "Nama Siswa", key: "student_name" },
    { label: "ID Siswa", key: "student_id" },
    { label: "Point", key: "marks_obtained" },
    { label: "Tanggal Ujian", key: "date" },
  ];

  const GetAllProjects = (params, setData, slug, setTotal) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slug)
      .then((res) => {
        console.log(res.data)
        setData(res.data)
        setTotal(res.headers["x-wp-total"])
        if (slug == slugs.db_slug_paid_results)
          setCustomersList(res.data)
        if (slug == slugs.db_slug_user_data) {
          setStudentsList1(res.data)
          GetAllExam(res.data)
        }
        // CSVData(res.data, UserData)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  const GetAllExam = (UserData) => {
    // setReload(true)
    setProjects1([])
    AdsGETApiAuth({
      per_page: 49000,
      _fields: "id,title,slug,meta"
    }, slugs.db_slug_paid_results)
      .then((res) => {
        setProjects1(res.data)
        setAllTotal(res.headers["x-wp-total"])
        setCustomersList(res.data)
        CSVData(res.data, UserData)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        // setReload(false)
      })
  }

  useEffect(() => {
    getAll()
  }, [])
  const getAll = () => {
    // GetAllProjects({
    //   per_page: 4900,
    //   _fields: "id,title,slug,meta"
    // }, setProjects1, slugs.db_slug_paid_results, setAllTotal)
    GetAllProjects({
      per_page: 500,
      _fields: "id,title,slug,meta.available_unavailable,meta.exam_type"
    }, setExamList, slugs.db_slug_paid_exam, setExamTotal)
    GetAllProjects({
      per_page: 4900,
      _fields: "id,title,slug,meta.user_phone_number,meta.batch_name,meta.user_id"
    }, setStudentsList, slugs.db_slug_user_data, setStudentTotal)
    // GetTotal("student", setStudentTotal, slugs.db_slug_user_data)
    // GetTotal("", setExamTotal, slugs.db_slug_paid_exam)
    // GetTotal("", setAllTotal, slugs.db_slug_paid_results)
    BatchList()
  }

  const BatchList = () => {
    AdsGETApiAuth(
      { per_page: 100, _fields: "id,title,meta.batch_status,meta.batch_start_date,meta.batch_end_date" },
      slugs.db_slug_batch
    )
      .then((res) => {
        setBatchList(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  const columnsExam = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      width: "100px"
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true,
    },
    {
      id: 33,
      name: "Availability",
      selector: (row) => row.meta.available_unavailable,
      sortable: true,
      reorder: true,
      width: "150px"
    },
    {
      id: 34,
      name: "Type",
      selector: (row) => row.meta.exam_type,
      sortable: true,
      reorder: true
    },
    {
      id: 34,
      name: "Total Exams",
      selector: (row) => CustomersList.filter(a => a.meta.exam_id_results == row.id).length,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: "Choose",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          setProjects1(CustomersList.filter(a => a.meta.exam_id_results == row.id))
          setChoose(0)
        }}
      >
        Choose
      </div>,

    }
  ];
  const columnsStudent = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.meta.user_id,
      sortable: true,
      reorder: true,
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true
    },
    {
      id: 312,
      name: "Phone",
      selector: (row) => row.meta.user_phone_number,
      sortable: true,
      reorder: true
    },
    {
      id: 32,
      name: "Batch",
      selector: (row) => row.meta.batch_name,
      sortable: true,
      reorder: true
    },
    {
      id: 3421,
      name: "Total Exams",
      selector: (row) => CustomersList.filter(a => a.meta.student_id_results == row.meta.user_id).length,
      sortable: true,
      reorder: true
    },
    {
      id: 3123,
      name: "Choose",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          setProjects1(CustomersList.filter(a => a.meta.student_id_results == row.meta.user_id))
          setChoose(0)
        }}
      >
        Choose
      </div>,

    }
  ];
  const columns = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      width: "100px",
      padding: 0,
      compact: true,
    },
    {
      id: 31,
      // name: "Exam",
      name: "Set Soal",
      selector: (row) => row.meta.exam_name_results,
      sortable: true,
      reorder: true,
      width: "150px",
      padding: 0,
      compact: true,
    },
    {
      id: 313,
      // name: "Student Name",
      name: "Nama Siswa",
      selector: (row) =>
        StudentsList.filter(a => a.meta.user_id == row.meta.student_id_results).length !== 0 ?
          StudentsList.filter(a => a.meta.user_id == row.meta.student_id_results)[0].title.rendered : ""
      ,
      padding: 0,
      sortable: true,
      reorder: true,
      compact: true,
    },
    {
      id: 312,
      name: "Set UBT ID",
      selector: (row) => row.meta.exam_id_results,
      sortable: true,
      compact: true,
      reorder: true
    },
    {
      id: 314,
      // name: "Total Score",
      name: "Point",
      // selector: (row) => row.meta.exam_total_results,
      selector: (row) => (row.meta.exam_total_results / JSON.parse(row.meta.exam_answers_array_results).length * 100).toFixed(2),
      sortable: true,
      compact: true,
      reorder: true
    },
    {
      id: 315,
      // name: "Date",
      name: "Tanggal",
      selector: (row) => row.meta.exam_date_results,
      sortable: true,
      compact: true,
      reorder: true
    },
    {
      id: 32,
      // name: "Delete",
      name: "Hapus",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          AdsPUTApi({
            status: "draft"
          }, slugs.db_slug_paid_results, row.id)
            .then((res) => {
              setSearchStudent("")
              setSearchExam("")
              setSearchExamID("")
              getAll()
            })
            .catch((err) => {
              console.log(err)
            })
        }}
      >Hapus</div>,

    },
    {
      id: 32,
      // name: "View",
      name: "Lihat",
      right: true,
      cell: (row) => <Link to={`${slugs.result_admin_view}/${row.slug}`}>Lihat</Link>,

    }
  ];
  const columnsBatch = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
    },
    {
      id: 31,
      name: "Nama Kelas",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true
    },
    {
      id: 312,
      name: "History",
      selector: (row) => CustomersList.filter(a => a.meta.batch_id == row.id).length,
      sortable: true,
      reorder: true
    },
    {
      id: 312,
      name: "Status Kelas",
      selector: (row) => row.meta.batch_status,
      // selector: (row) => row.meta.batch_status,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: "Lihat",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          const ResultFilter = CustomersList.filter(a => a.meta.batch_id == row.id)
          setProjects1(ResultFilter)
          setChoose(0)
        }}
      >
        Lihat
      </div>,

    }
  ];

  const CSVData = (ArrayData, UserData) => {
    var dData = []
    for (let i = 0; i < ArrayData.length; i++) {
      var row = ArrayData[i]
      console.log(UserData.filter(a => a.meta.user_id == row.meta.student_id_results))
      dData.push({
        id: row.id,
        name: row.meta.exam_name_results,
        student_id: row.meta.student_id_results,
        student_name:
          UserData.filter(a => a.meta.user_id == row.meta.student_id_results).length !== 0 ?
            UserData.filter(a => a.meta.user_id == row.meta.student_id_results)[0].title.rendered : "",
        // marks_obtained: row.meta.exam_total_results,
        marks_obtained:(row.meta.exam_total_results / JSON.parse(row.meta.exam_answers_array_results).length * 100).toFixed(2),
        date: row.meta.exam_date_results
      })
    }
    setCustomersDownload(dData)
  }
  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          {/* <h2>All Results</h2> */}
          <h2>Hasil Ujian</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Hasil Ujian
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div style={{ display: "inline", cursor: "pointer" }}>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(0)
                setProjects1(CustomersList)
              }}
            >
              {/* All ({AllTotal}) | */}
              Semua ({AllTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(1)
              }}
            >
              {/* Exams ({ExamTotal}) | */}
              Soal UBT ({ExamTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(2)
              }}
            >
              {/* Students ({StudentTotal}) | */}
              Siswa ({StudentTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(3)
              }}
            >
              Kelas ({batchList.length}) |
            </div>
            {/* <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setProjects1(CustomersList)
              }}
            >
              Clear
            </div> */}
             {/* <div style={{ display: "inline-block", margin: "0px 5px" }}>
              <CSVLink data={CustomersDownload} headers={headers} className="Link"
                filename={`chapterResults-${date.toLocaleString()}.csv`}
              >
                Download CSV
              </CSVLink>
            </div> */}
            {Projects2.length == 0 ?
              <div className="col-3" />
              :
              <div className="col-3">
                <div style={{ display: "inline-block", margin: "0px 5px", cursor: "pointer" }}
                  onClick={() => {
                    setBulkDelete(true)
                  }}
                >
                  Hapus Semua
                </div>
              </div>
            }
          </div>
        </div>
        <div className="col-md-6 row">
          <div className="col-4" />
          <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachExam} placeholder="Exam Name"
            onChange={(event) => {
              setSearchExam(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.exam_name_results.includes(event.target.value)))
            }}
          />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachExamID} placeholder="Exam ID"
            onChange={(event) => {
              setSearchExamID(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.exam_id_results.includes(event.target.value)))
            }}
          />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachStudent} placeholder="Student ID"
            onChange={(event) => {
              setSearchStudent(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.student_id_results.includes(event.target.value)))
            }}
          />
        </div>
      </div>


      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        {choose == 0 &&
          <DataTable
            columns={columns}
            data={Projects1}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
            onSelectedRowsChange={(r) => {
              console.log("asdasd", r)
              setProjects2(r.selectedRows)
            }}
          />
        }
        {choose == 1 &&
          <>
            <DataTable
              columns={columnsExam}
              data={ExamList}
              defaultSortFieldId={1}
              defaultSortAsc={false}
              striped
              // sortIcon={<SortIcon />}
              pagination
              selectableRows
            />
          </>
        }
        {choose == 2 &&
          <DataTable
            columns={columnsStudent}
            data={StudentsList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
          />
        }
        {choose == 3 &&
          <DataTable
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 50, 100, 1000]}
            columns={columnsBatch}
            data={batchList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            pagination
            selectableRows={false}
          />
        }
      </div>
      {bulkDelete &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-4 col-1"></div>
            <div className="col-md-4 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setBulkDelete(false)
                  }}
                >X</div>
                Apakah anda yakin ingin menghapus semua hasil ujian ??<br />
                <tr>
                  <td colSpan={6}>
                    <div className="row">
                      <div className="col-1" />
                      <div className="col-5 center " >
                        <div className="sign-in-button-4" style={{ width: "80%" }}
                          onClick={() => {
                            setBulkDelete(false)
                          }}
                        >
                          Batal
                        </div>
                      </div>
                      <div className="col-5 center">
                        <div className="sign-in-button-4" style={{ width: "80%" }}
                          onClick={() => {
                            setBulkDelete(false)
                            for (let i = 0; i < Projects2.length; i++) {
                              setReload(true)
                              AdsPUTApi({
                                status: "draft"
                              }, slugs.db_slug_paid_results, Projects2[i].id)
                                .then((res) => {
                                  if (i == Projects2.length - 1) {
                                    getAll()
                                  }
                                })
                                .catch((err) => {
                                  console.log(err)
                                })

                            }
                          }}
                        >
                          Hapus Semua
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </div>
            </div>
          </div>
        </section>

      }
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}